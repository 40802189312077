<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  mounted() {

  }
}
</script>

<style>

header, .v-application {
  padding-top: env(safe-area-inset-top);
}


@import url('assets/fonts/pretendardvariable.css');
@font-face {
  font-family: "League Spartan";
  src: url('assets/fonts/LeagueSpartan-VariableFont_wght.ttf');
}

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard-gov.min.css");
   

html, body, div, h1, h2, h3, h4, h5, input, textarea, label, select, span, p, li {
/* font-family: 'Pretendard-Regular', sans-serif; */
/* font-family: -apple-system, BlinkMacSystemFont, "Apple SD Gothic Neo", "Pretendard Variable", Pretendard, Roboto, "Noto Sans KR", "Segoe UI", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif; */
    font-family: "Pretendard GOV", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;                                    
}

td.ellipsis-cell {
  overflow: hidden !important;
  white-space: nowrap !important;  
  text-overflow: ellipsis !important;
}

.hot-table-card {
  padding-bottom : 50px;
}

:root {
    --side-menu-width: 0px;
    --toolbar-height : 50px;
    --margin-5 : 5px;
    --margin-10 : 10px;
    --margin-20 : 20px;
    --margin-30 : 30px;
    --margin-40 : 40px;
    --margin-50 : 50px;
    --margin-60 : 60px;
    --margin-70 : 70px;
    --margin-80 : 80px;
    --margin-90 : 90px;
    --margin-100 : 100px;
    --primary-color: #2A2B2D;
    --secondary-color: #282D3C;
    --warning-color: #6C2831;
    --success-color: #00637C;
    --info-color: #3A5589;
    --danger-color: #6C2831;
    --verify-color: #D6C69A;
}


.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}
  

.color-primary {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}
.color-secondary {
    background-color: var(--secondary-color) !important;
    color: #fff !important;
}
.color-warning {
    background-color: var(--warning-color) !important;
    color: #fff !important;
}
.color-success {
    background-color: var(--success-color) !important;
    color: #fff !important;
}
.color-info {
    background-color: var(--info-color) !important;
    color: #fff !important;
}
.color-danger {
    background-color: var(--danger-color) !important;
    color: #fff !important;
}
.color-verify {
    background-color: var(--verify-color) !important;
    color: #000;
}
.underline {
    
}

.main-button {
  background-color: #F0F3F6 !important;
  color : #383E43 !important;
  border-radius: 10px !important;
  height: 45px !important;
  font-size: 15px !important;
  font-weight: 700 !important;
}

.v-container {  
  max-width : 1050px !important;
  padding: 40px 20px !important;
  margin : 0 auto !important;
}

@media screen and (max-width: 768px) {
  .v-container {
    padding: 20px 20px !important;
  }
}

.description {
  font-size: 0.8rem;
  margin-bottom : 20px;
  color: #555;
}

h1 {
  font-size : 1.6rem !important;
  margin-bottom : 10px !important;
  color : #2e2e2e !important;
}

ol,ul {
  margin-left : 10px;
}
ol,ul li {
  margin-left : 20px;
}

div.v-container.full-width {
  width: 100% !important;
  max-width : 100% !important;
}

</style>