<template>
  
  <v-app-bar app color="grey-darken-4" >
    <v-toolbar-title>컨텐츠 Admin Console</v-toolbar-title>
    
    <v-spacer></v-spacer>
    <div class="mr-10">{{ user.name }}님 안녕하세요.</div>
    <v-btn elevation="0" class="" color="white" size="large" rounded="lg" @click="openChatbot()">챗봇 열기</v-btn>
    <v-btn elevation="0" color="white" size="large" @click="logout">로그아웃</v-btn>
  </v-app-bar>
  <v-container class="full-width ma-0 pt-5" style="width:1800px;max-width:100%;">
    <div ref="loading" class="loading">      
        <v-progress-circular id="loading-progress" indeterminate color="white"></v-progress-circular>      
    </div>
    <v-row style="margin-top:10px;">
      <v-col cols="7">
        <!-- <h2 class="mb-5">컨텐츠 목록</h2> -->
        <v-row>
          <v-col cols="2" class="mr-0 pr-0">
            <v-text-field hide-details label="페이지" v-model="page" rounded="lg" variant="outlined" density="compact"></v-text-field>
          </v-col>
          <v-col>
            <v-btn elevation="0" class="mr-5" color="black" rounded="lg" @click="getContents">조회</v-btn>
            <v-btn elevation="0" class="mr-1" color="black" rounded="lg" @click="prev">이전</v-btn>
            <v-btn elevation="0" class="mr-5" color="black" rounded="lg" @click="next">다음</v-btn>
            <v-btn elevation="0" class="mr-1" color="black" rounded="lg" @click="classify()">분야 분류</v-btn>
            <!-- <v-btn elevation="0" class="ml-16" color="black" size="large" rounded="lg" @click="logout()">로그아웃</v-btn> -->
          </v-col>
          <v-col>
            <v-row>
              <v-col><v-text-field style="max-height:36px !important" hide-details label="검색" v-model="search" rounded="lg" variant="outlined" density="compact"></v-text-field></v-col>
              <v-col><v-btn elevation="0" class="mr-1" color="black" rounded="lg" @click="searchContents">검색</v-btn></v-col>
            </v-row>
            
            
          </v-col>
        </v-row>

        <v-row class="mb-1">
          <v-col class="mt-0 pt-0 mb-0">
            <v-btn elevation="0" class="mr-5" color="grey-darken-3" size="default" rounded="lg" @click="filter()">전체</v-btn>
            <v-btn elevation="0" class="mr-1" color="grey-darken-3" size="default" rounded="lg" @click="filter('세무')">세무</v-btn>
            <v-btn elevation="0" class="mr-1" color="grey-darken-3" size="default" rounded="lg" @click="filter('법률')">법률</v-btn>
            <v-btn elevation="0" class="mr-1" color="grey-darken-3" size="default" rounded="lg" @click="filter('노무')">노무</v-btn>
            <v-btn elevation="0" class="mr-1" color="grey-darken-3" size="default" rounded="lg" @click="filter('기타')">기타</v-btn>
            
          </v-col>
        </v-row>

        <v-card class="pa-3 fill-height d-flex flex-column" style="max-height: calc(100vh - 230px);">
          <v-card-text style="padding:0px;" class="ma-0 pa-0 flex-grow-1 pa-0">
            <hot-table ref="contentsListTable" :settings="settings" licenseKey="non-commercial-and-evaluation"></hot-table>
          </v-card-text>
        </v-card>
        
      </v-col>
      <v-col cols="5">
        <template v-if="question != ''">
        <v-card style="margin-top:100px;padding-top:20px;" rounded="lg" class="content-box" elevation="0">
          <v-card-text>
            <v-row class="mb-1 ml-3">
            <v-col>
              <!-- <v-btn elevation="0" class="mr-1" color="black" size="large" rounded="lg" @click="target">대상지정</v-btn>            
              <v-btn elevation="0" class="mr-1" color="black" size="large" rounded="lg" @click="untarget">대상해지</v-btn>             -->
              <v-btn elevation="0" class="mr-1" color="black" rounded="lg" @click="verify">검증완료</v-btn>            
              <v-btn elevation="0" class="mr-10" color="black" rounded="lg" @click="unverify">검증해지</v-btn>            
              <v-btn elevation="0" class="mr-1" color="black" rounded="lg" @click="modify">수정하기</v-btn>

              <v-btn elevation="0" class="mr-1" color="black" rounded="lg" @click="copyLink">링크복사</v-btn>
              <v-btn elevation="0" class="mr-1" color="black" rounded="lg" @click="openLink">링크열기</v-btn>
              
              
            </v-col>
          </v-row>
            <div class="ma-5">              
              <h2 style="line-height: 160%;">{{ question }}</h2>
            </div>
            
            <div class="ma-5">
              <div class="contents mb-5" v-html="contents"></div>
            
              <template v-if="selected.answer">
                <!-- "http", "https"가 link에 포함된 경우 -->                 
                <template v-for="link in selected.answer.source" :key="link">                  
                  <p v-if="link.includes('http')" ><a :href="link" target="_blank">{{ link }}</a></p>
                  <p v-else>{{ link }}</p>
                </template>                
              </template>
            </div>  
          </v-card-text>
        </v-card>
        </template>

        <template v-if="related.length > 0">
          <h2 class="mb-5 mt-10">관련질문</h2>
          <v-card rounded="lg" class="mt-3 pl-5 related-question" elevation="0" v-for="related in related" :key="related">
            <v-card-text class="">
               <a :href="contentsLink(related.question_id)" target="_blank">{{ related.question }}</a>
            </v-card-text>
          </v-card>
        </template>
        
      </v-col>
    </v-row>

    <!-- 로그인 모달 -->
    <v-dialog v-model="login_status" width="700px">
      <v-card>
        <v-card-text>
            <h2 class="mb-5">로그인</h2>
            <v-text-field class="mb-5" hide-details label="유저id" v-model="username" rounded="lg" variant="outlined" density="comfortable"></v-text-field>
            <v-text-field class="mb-5" hide-details label="비밀번호" v-model="password" rounded="lg" variant="outlined" type="password" density="comfortable"></v-text-field>      
            <v-btn elevation="0" class="mr-1 mb-5" block color="black" size="large" rounded="lg" @click="login">로그인</v-btn>
        </v-card-text>
      </v-card>      
    </v-dialog>
    <!-- 수정하기 모달 -->
     <v-dialog v-model="modify_status" width="700px">
      <v-card>
        <v-card-text style="padding:0px;padding-bottom:20px;">
            <h2 class="mb-5">수정하기</h2>
            <v-textarea class="mb-5" rows="15" hide-details v-model="selected.answer.answer" rounded="lg" variant="outlined" density="comfortable"></v-textarea>
            <v-btn elevation="0" class="mr-1 mb-5" block color="black" size="large" rounded="lg" @click="modifyUpdate">수정하기</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { HotTable } from '@handsontable/vue3';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.css';
import { registerLanguageDictionary, koKR } from 'handsontable/i18n';
import axios from 'axios';
import showdown from 'showdown';

registerLanguageDictionary(koKR);
registerAllModules();

export default {
  name: 'AdminContents',
  components: {
    HotTable
  },
  data() {
    return {
      settings: {
        dropdownMenu: true,
        height:'100%',
        // width: '100%',
        // stretchH: 'all',
        selectionMode: 'single',
        colHeaders: true,
        rowHeaders: true,
        columnSorting: true,
        filters: true,
        language: koKR.languageCode,
        columns: [
          { title: 'ID', data: 'id', className:'text-center', type: 'numeric', width: 50, readOnly: true },
          { 
            title: '질문', 
            data: 'question', 
            type: 'text', 
            width: 565, 
            className : 'ellipsis-cell',
            readOnly: true,            
          },
          { title: '분야', data: 'category', className:'text-center', type: 'dropdown', source: [null, '세무', '법률', '노무', '기타', '환급', '케어'],
            width: 60},
          { title: '대상', data: 'verify_target', className:'text-center', type: 'numeric', width: 30, readOnly: true },
          { title: '검증', data: 'verified', className:'text-center', type: 'numeric', width: 30, readOnly: true },
          { title: '검증자', data: 'verify_expert', className:'text-center ellipsis-cell', type: 'text', width: 100, readOnly: true },
          { title: '질문일시', data: 'createdAt', className:'text-center ellipsis-cell', type: 'text', width: 150, readOnly: true },
        ],
        licenseKey: 'non-commercial-and-evaluation'
      },
      contents: '',
      page: 1,
      data: [],
      selected: {},
      question: '',
      filter_value: '',
      login_status : false,
      username : '',
      password : '',
      modify_status : false,
      related : [],
      user : {},
      search : '',

    }
  },
  methods: {

    searchContents() {
      this.$refs.loading.style.display = 'block';
      var payload = {
        search: this.search
      }
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents/search', payload, this.config)
        .then((response) => {
          this.data = response.data;
          this.$refs.contentsListTable.hotInstance.loadData(this.data);
          if (this.filter_value != '') {
            this.filter(this.filter_value);
          }
        }).catch((error) => {
          console.log(error);
          this.login_status = true;
        }).finally(() => {
          this.$refs.loading.style.display = 'none';
        });

    },


    contentsLink(id) {
      return process.env.VUE_APP_SERVER_URL + '/contents/' + id;
    },


    modify() {
      console.log(this.selected);
      if(Object.keys(this.selected).length === 0) {
        alert('선택된 항목이 없습니다.');
        return;
      }
      this.modify_status = true;
    },

    modifyUpdate() {

      var id = this.selected.id;
      var answer = this.selected.answer;
      var payload = {
        id: id,
        answer: answer
      }
      
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents/modify', payload, this.config)
      .then((response) => {
        console.log(response);
        this.modify_status = false;
        this.getContents();
      })
      .catch((error) => {
        console.log(error);
      });

    },


    getContents() {

      if (this.search != '') {
        this.searchContents();
        return;
      }

      this.$refs.loading.style.display = 'block';
      var payload = {
        page: this.page
      }
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents', payload, this.config)
        .then((response) => {
          this.data = response.data;
          this.$refs.contentsListTable.hotInstance.loadData(this.data);

          if (this.filter_value != '') {
            this.filter(this.filter_value);
          }
        }).catch((error) => {
          console.log(error);
          this.login_status = true;
        }).finally(() => {
          // this.$nextTick(() => {          
          // });        
          this.$refs.loading.style.display = 'none';
          
        });
    },


              

    next() {
      this.page++;
      this.getContents();
    },


    prev() {
      this.page--;
      this.getContents();
    },


    target() {
      console.log(this.selected);
      if(Object.keys(this.selected).length === 0) {
        alert('선택된 항목이 없습니다.');
        return;
      }
      var id = this.selected.id;
      var payload = {
        id: id
      }
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents/target', payload, this.config)
      .then((response) => {
        console.log(response);
        this.getContents();
      })
      .catch((error) => {
        console.log(error);
      });
    },

    untarget() {
      console.log(this.selected);
      if(Object.keys(this.selected).length === 0) {
        alert('선택된 항목이 없습니다.');
        return;
      }
      var id = this.selected.id;
      var payload = {
        id: id
      }
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents/untarget', payload, this.config)
      .then((response) => {
        console.log(response);
        this.getContents();
      })
      .catch((error) => {
        console.log(error);
      });
    },
    

    verify() {
      console.log(this.selected);
      if(Object.keys(this.selected).length === 0) {
        alert('선택된 항목이 없습니다.');
        return;
      }
      this.target();
      var id = this.selected.id;
      var payload = {
        id: id,
        question : this.question
      }
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents/verify', payload, this.config)
      .then((response) => {
        console.log(response);
        this.getContents();
      })
      .catch((error) => {
        console.log(error);
      });
    },


    login() {
      // form data로 보내기
      var url = process.env.VUE_APP_SERVER_URL + '/token';
      var formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);
      axios.post(url, formData)
      .then((response) => {
        console.log(response);
        localStorage.setItem('token', response.data.access_token);
        this.login_status = false;
        this.config.headers.Authorization = 'Bearer ' + response.data.access_token;
      }).finally(() => {
        this.getContents();
      });
    },
      
    unverify() {
      console.log(this.selected);
      if(Object.keys(this.selected).length === 0) {
        alert('선택된 항목이 없습니다.');
        return;
      }
      this.untarget();
      var id = this.selected.id;
      var payload = {
        id: id
      }
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents/unverify', payload, this.config)
      .then((response) => {
        console.log(response);
        this.getContents();
      })
      .catch((error) => {
        console.log(error);
        this.login_status = true;
      });
    },


    classify() {
      var url = process.env.VUE_APP_SERVER_URL + '/api/contents/classify';
      this.$refs.loading.style.display = 'block';
      axios.get(url, this.config).then((response) => {
        console.log(response);
        this.getContents();
      }).catch((error) => {
        console.log(error);
        this.login_status = true;
      }).finally(() => {
        this.$refs.loading.style.display = 'none';
      });

    },


    copyLink() {
      var url = process.env.VUE_APP_SERVER_URL + '/contents/' + this.selected.id;
      navigator.clipboard.writeText(url).then(() => {
        alert('링크가 복사되었습니다.');
      });
    },

    openLink() {
      var url = process.env.VUE_APP_SERVER_URL + '/contents/' + this.selected.id;
      window.open(url, '_blank');
    },

    
    filter(value) {
      this.filter_value = value;
      // hot에서 분야를 기준으로 필터링하기
      var hot = this.$refs.contentsListTable.hotInstance;        
      // 기존 필터 해제
      hot.getPlugin('filters').clearConditions();

      if (value) {
        // console.log(value);
        hot.getPlugin('filters').addCondition(2, 'eq', [value]);      
      }
      hot.getPlugin('filters').filter();      
    },

    session() {
      
      axios.get(process.env.VUE_APP_SERVER_URL + '/users/me', this.config)
      .then((response) => {
        console.log(response);
        this.user = response.data;
      })

    },

    
    logout() {
      // username, password 초기화
      this.username = '';
      this.password = '';
      localStorage.removeItem('token');
      // hot 초기화
      this.$refs.contentsListTable.hotInstance.loadData([]);
      this.login_status = true;
    },


    openChatbot() {
      var url = process.env.VUE_APP_SERVER_URL + '/';
      // 새창으로 600*800으로 열기
      window.open(url, 'chatbot', 'width=600, height=800');
    },

    getRelatedQuestions() {
      this.related = [];
      var url = process.env.VUE_APP_SERVER_URL + '/api/contents/related';
      var payload = {
        id : this.selected.id,
        question : this.question
      }
      axios.post(url, payload, this.config)
      .then((response) => {
        // console.log(response);
        this.related = response.data;
      })
    }



  },

  computed : {
    config() {
      var headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
      return {
        headers: headers
      }
    }
  },


  mounted() {

    // session 검증
    // localstorage에서 token 가져오기
    var token = localStorage.getItem('token');
    if (token == undefined) {
      this.login_status = true;
      this.$refs.loading.style.display = 'none';
    }
    else {
      this.session();
      this.getContents();
      
    }

    let lastSelectedRow = null;

    this.$refs.contentsListTable.hotInstance.addHook('afterSelection', (r) => {
      var real_r = this.$refs.contentsListTable.hotInstance.toPhysicalRow(r);
      
      // Check if the selection has changed
      if (real_r !== lastSelectedRow) {
        lastSelectedRow = real_r;
        
        if (typeof this.data[real_r].answer === 'string') {
          var answer = JSON.parse(this.data[real_r].answer);
        } else {
          answer = this.data[real_r].answer;
        }
        var converter = new showdown.Converter()
        this.contents = answer.answer;
        this.contents = converter.makeHtml(this.contents);
        this.selected = this.data[real_r];         
        if (typeof this.selected.answer === 'string') {
          this.selected.answer = JSON.parse(this.selected.answer);
        } 
        this.question = this.data[real_r].question;
        
        this.$nextTick(() => {
          this.getRelatedQuestions();
        });
      }
    });

    // category dropdown 변경되면 저장
    this.$refs.contentsListTable.hotInstance.addHook('afterChange', (changes, source) => {
      if(source === 'edit') {
        var row = changes[0][0];
        var real_r = this.$refs.contentsListTable.hotInstance.toPhysicalRow(row);
        var value = changes[0][3];
        var id = this.data[real_r].id;
        var payload = {
          id: id,
          category: value
        }

        axios.post(process.env.VUE_APP_SERVER_URL + '/api/contents/category', payload, this.config)
        .then((response) => {
          console.log(response);
          alert("변경되었습니다.");
          
        })
        .catch((error) => {
          console.log(error);
        });
      }
    });
    
    // title 변경
    document.title = '비즈넵 SeNa - 컨텐츠 Admin Console';

    

  }
}
</script>

<style scoped>


.handsontable {
  width: 100%;
  height: 100%;
}


.v-container {
  background-color: #f5f5f5;
}

.contents {
  line-height: 180% !important; 
}
div.contents ol {
  margin-left: 10px !important;
} 


.full-width {
  width: 100% !important;
}

.v-field__input {
  min-height:44px;
  height:44px;
}

.content-box {
  /* 테두리 */
  border: 1px solid #e0e0e0;
  
}

.loading {
  display: none;
  position: fixed;
  top : 0px;
  left : 0px;
  /* top: 50%;
  left: 50%;  
  transform: translate(-50%, -50%); */
  z-index: 9999;
  /* 배경 흐리게 */
  background-color: rgba(0, 0, 0, 0.5);
  /* blur 효과 */
  backdrop-filter: blur(5px);
  /* 크기 */
  width: 100%;
  height: 100%;  
  

}

#loading-progress {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
}

.related-question {
  /* cursor: pointer; */
  border: 1px solid #e0e0e0;
}

.related-question a {
  color: black;
  /* 밑줄 제거 */
  text-decoration: none;
}

.related-question a:hover {
  color: black;
}

.v-card {
  overflow: hidden;
}
</style>