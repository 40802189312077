import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/AboutView.vue'
import adminContents from '../views/adminContents.vue'
const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/chat.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "about" */ '../views/chat.vue')
  },
  {
    path: '/lawna',
    name: 'lawna',
    component: () => import(/* webpackChunkName: "about" */ '../views/chat.vue')
  },
  {
    path: '/admin/contents',
    name: 'adminContents',
    component: adminContents
  },


  {
    path: '/calc',
    name: 'calcHome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/calcHome.vue'),
  },


  {
    path: '/sales',
    name: 'Sales',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/Sales.vue'),
  },
  {
    path: '/salary',
    name: 'Salary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/Salary.vue'),
  },
  {
    path: '/salary/actual',
    name: 'SalaryActual',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/SalaryAfterTax.vue'),
  },
  {
    path: '/bep',
    name: 'Bep',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/Bep.vue'),
  },
  {
    path: '/tax/fee',
    name: 'TaxFee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/TaxFee.vue'),
  },
  {
    path : '/tax/simplevat', 
    name : 'TaxSimpleVat',
    component : () => import('@/views/TaxSimpleVat.vue')
  },
  {
    path : '/tax/credit/labor', 
    name : 'TaxCreditLabor',
    component : () => import('@/views/TaxCreditLabor.vue')
  },
  {
    path : '/tax/penalty', 
    name : 'TaxPenalty',
    component : () => import('@/views/TaxPenalty.vue')
  },

  {
    path : '/tax/vat', 
    name : 'TaxVat',
    component : () => import('@/views/TaxVatCalc.vue')
  },

  {
    path : '/calc/depreciation', 
    name : 'CalcDepreciation',
    component : () => import('@/views/DepreciationCar.vue')
  },



  {
    path : '/editor', 
    name : 'Editor',
    component : () => import('@/views/ArticleEditor.vue')
  },
  {
    path: '/post/:id',
    name: 'postView',
    // component: PostView
    component: () => import(/* webpackChunkName: "home" */ '@/views/PostView.vue'),
  },

  {
    path : '/holidaypay',
    name : 'HolidayPay',
    component : () => import('@/views/HolidayPay.vue')

  },

  {
    path : '/payroll',
    name : 'Payroll',
    component : () => import('@/views/payroll.vue')

  },

  {
    path : '/payroll/edit',
    name : 'PayrollEdit',
    component : () => import('@/views/payrollEdit.vue')

  }

]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
